import { useRouter } from 'next/router';
import React, {
  useCallback, useEffect, useRef
} from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onSignInRailSuccess, signInFormView } from '@/dataAnalytics/dataAnalyticsHelper';
import YumAuthCallbackApp, { COMMON_AUTH_REQUEST_KEY } from '@/auth/YumAuthCallbackApp';
import useYumAuthListener, { messageTypeMatches } from './useYumAuthListener';
import { closeRail } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import { setIsAuthenticated } from '@/../optimizely/utils/attributeHelpers';
import telemetry from '@/telemetry';
import { RootState } from '@/rootStateTypes';
import { AUTH_TOKEN_UPDATED_EVENT, initializeAuthTokenHelpers } from '@/auth/authTokenHelpers';
import { AuthState } from '@/auth/userStates';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { openModal } from '@/localization/actions';
import { INITIALIZATION_TIME_LIMIT } from '@/configuration/constants';
import { YumMessagePredicateWithCallback } from './UseYumAuthListener.types';
import Routes from '@/router/routes';
import { RESET_PASSWORD_TITLE, SIGN_IN_TITLE } from '@/rail/railContent/signInRail/constants';
import { setAuthState } from '@/header/actions';
import ParamRoutes from '@/router/paramRoutes';
import { userDomainSelectors } from '@/header/userDomainSelectors';

interface UseYumSignInProps {
  setRailTitle: (title: string) => void;
}

type UseYumSignIn = (props: UseYumSignInProps) => {
  railStatus: string | string[];
};

export const isAuthSuccessful = messageTypeMatches('idp-auth-successful');
export const isAuthFailure = messageTypeMatches('idp-auth-failure');
export const isInitialized = messageTypeMatches('idp-ui-initialized');
export const isCreateAccountRequest = messageTypeMatches('idp-create-account-request');
export const isExistingSessionFound = messageTypeMatches('idp-auth-existing-session-found');
export const isForgotPassword = messageTypeMatches('idp-forgot-password');
export const isForgotPasswordConfirmation = messageTypeMatches('idp-forgot-password-confirmation');

const useYumSignIn: UseYumSignIn = (props: UseYumSignInProps) => {
  const { setRailTitle } = props;
  const [{ enabled: isRedirectToHutsRewardsDashboardEnabled }] = useDecision('fr-dtb-1284-hut-rewards-dashboard-redirect-on-signin');
  const router = useRouter();
  const [onAuthSuccessCalled, setOnAuthSuccessCalled] = React.useState(false);
  const { query: { afterAuth } } = router;
  const isUserLoading = useSelector(userDomainSelectors.isLoading);
  const isLoggedIn = useSelector(userDomainSelectors.isAuthenticatedUser);

  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const isLocalized = useSelector(localizationSelectors.isLocalized);
  const localizationToken = useSelector(localizationSelectors.localizationToken);
  const failureTimeout = useRef<NodeJS.Timeout>();
  const railStatus = router.query?.railStatus || '';
  const shouldRedirectToHutsRewardsDashboard = isRedirectToHutsRewardsDashboardEnabled
  && onAuthSuccessCalled
  && afterAuth
  && typeof afterAuth === 'string'
  && Object.keys(ParamRoutes).includes(afterAuth)
  && !isUserLoading
  && isLoggedIn;

  useEffect(() => {
    if (shouldRedirectToHutsRewardsDashboard) {
      router.push(ParamRoutes[afterAuth as keyof typeof ParamRoutes]);
      dispatch(closeRail(RailType.SIGN_IN));
    }
  }, [shouldRedirectToHutsRewardsDashboard, afterAuth, router, dispatch]);

  const onAuthSuccess = useCallback(async () => {
    setOnAuthSuccessCalled(true);
    dispatch(setAuthState(AuthState.LOGGED_IN));
    setIsAuthenticated(true);

    if (isRedirectToHutsRewardsDashboardEnabled && !afterAuth) {
      dispatch(closeRail(RailType.SIGN_IN));
    }

    telemetry.addCustomEvent('onSuccessfulLogin - initializing authTokenHelpers');
    window.dispatchEvent(new CustomEvent(AUTH_TOKEN_UPDATED_EVENT));

    await initializeAuthTokenHelpers({
      getState: store.getState,
      dispatch: store.dispatch,
      localized: isLocalized,
      initLocalizationToken: localizationToken,
      userAuthentication: { authState: AuthState.LOGGED_IN, expirationDate: 0 },
      shouldCreateCart: true
    });

    analytics.push(() => onSignInRailSuccess());
  }, [
    dispatch,
    store.getState,
    store.dispatch,
    isLocalized,
    localizationToken,
    analytics,
    afterAuth,
    isRedirectToHutsRewardsDashboardEnabled
  ]);

  const onAuthFailure = useCallback(() => {
    telemetry.addCustomEvent('onFailedLogin');
    dispatch(openModal({
      title: 'We\'re sorry',
      body: 'Something went wrong. Please try again.',
      cta: {
        text: 'OK'
      }
    }));
    dispatch(closeRail(RailType.SIGN_IN));
  }, [dispatch]);

  const onAuthIframeInitialized = useCallback(() => {
    telemetry.addCustomEvent('onYumSignInIframeInitialized');
    clearTimeout(failureTimeout.current);
    setRailTitle(SIGN_IN_TITLE);
  }, [setRailTitle]);

  const onCreateAccountRequest = useCallback(() => {
    dispatch(closeRail(RailType.SIGN_IN));
    router.push(Routes.ACCOUNT.CREATE_ACCOUNT);
  }, [dispatch, router]);

  const onIsForgotPasswordOrConfirmation = useCallback(() => {
    if (setRailTitle) {
      setRailTitle(RESET_PASSWORD_TITLE);
    }
  }, [setRailTitle]);

  const listeners = useRef<YumMessagePredicateWithCallback[]>([
    { predicate: isAuthSuccessful, callback: onAuthSuccess },
    { predicate: isAuthFailure, callback: onAuthFailure },
    { predicate: isInitialized, callback: onAuthIframeInitialized },
    { predicate: isExistingSessionFound, callback: onAuthIframeInitialized },
    { predicate: isCreateAccountRequest, callback: onCreateAccountRequest },
    { predicate: isForgotPassword, callback: onIsForgotPasswordOrConfirmation },
    { predicate: isForgotPasswordConfirmation, callback: onIsForgotPasswordOrConfirmation }
  ]);
  useYumAuthListener(listeners.current);

  useEffect(() => {
    analytics.push(() => signInFormView());
  }, [analytics]);

  // Initialization failure timeout
  useEffect(() => {
    failureTimeout.current = setTimeout(() => {
      onAuthFailure();
    }, INITIALIZATION_TIME_LIMIT);

    return () => {
      clearTimeout(failureTimeout.current);
    };
  }, [onAuthFailure]);

  // Authorization request
  useEffect(() => {
    const application = new YumAuthCallbackApp();
    application.makeAuthorizationRequest('yum-sign-in-iframe-container');

    return () => {
      // eslint-disable-next-line no-plusplus
      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (key && key.includes(COMMON_AUTH_REQUEST_KEY)) {
          localStorage.removeItem(key);
        }
      }
    };
  }, []);

  return {
    railStatus
  };
};

export default useYumSignIn;
