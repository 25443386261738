import React from 'react';

import { capitalize } from '@/utils';
import { useLoyaltyPoints } from '@/clientCore/loyalty';

import {
  breakPointsValues,
  largeStartBreakpoint,
  smallMobileStartBreakpoint
} from '../../materialUi/theme';

interface CustomerGreetingProps {
  windowSize: any;
  customerDetails: CustomerDetails;
}

const CustomerGreeting = ({ customerDetails, windowSize }: CustomerGreetingProps) => {
  const { currentPoints } = useLoyaltyPoints();

  const isMobile = windowSize.width && windowSize.width < breakPointsValues[largeStartBreakpoint];
  const firstName = customerDetails?.firstName;
  let greeting = 'Hi there';

  if (firstName && firstName?.length <= 10) {
    greeting = `Hi, ${capitalize(firstName)}`;
  }

  const smallMobileScreen = windowSize.width < breakPointsValues[smallMobileStartBreakpoint];

  const hasPoints = currentPoints?.toLocaleString();
  const screenPointsCheck = smallMobileScreen || !hasPoints;

  return (
    <span data-testid="header-greeting-data">
      { isMobile ? screenPointsCheck && 'Hi' : greeting }
    </span>
  );
};

export default CustomerGreeting;
