import { useSelector } from 'react-redux';
import { useGetPointsQuery } from '@pizza-hut-us-development/client-core';

import { RootState } from '@/rootStateTypes';
import { userDomainSelectors } from '@/header/userDomainSelectors';

type UseLoyaltyPoints = {
  currentPoints?: number;
};

export const useLoyaltyPoints = (): UseLoyaltyPoints => {
  const isLoggedIn = useSelector(userDomainSelectors.isAuthenticated);
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const {
    data
  } = useGetPointsQuery(undefined, { skip: !isLoggedIn || !isYumEcomm });

  return {
    currentPoints: data?.currentValue
  };
};
