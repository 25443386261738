import Routes from './routes';

interface ParamRoutesData {
  rewards: string;
  profile: string;
}

const ParamRoutes: ParamRoutesData = {
  rewards: Routes.ACCOUNT.REWARDS,
  profile: Routes.ACCOUNT.PROFILE
};

export default ParamRoutes;
